/* Recharts changes done by Anish D on 30th Nov, 2023*/
.recharts-legend-wrapper {
  margin-top: 0px !important;
  .recharts-default-legend {
    margin-top: -7px !important;
  }
  ul {
    text-align: left !important;
    margin-left: 10px !important;
    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1;
    }
  }
}
.history-zoom-wrapper {
  .recharts-legend-wrapper {
    margin-top: 20px !important;
    height: auto !important;
    .recharts-default-legend {
      margin-top: 10px !important;
    }
    ul {
      margin-left: 55px !important;
      li {
        width: auto;
      }
    }
  }
}
.history-merge-wrapper {
  .recharts-legend-wrapper {
    margin-top: 0px !important;
    height: auto !important;
    .merge-legend-wrapper {
      margin-top: -7px !important;
    }
  }
}
.history-brush {
  font-size: 8px !important;
  .recharts-brush-traveller {
    outline: none;
  }
}
/*Recharts changes ends*/
.custom-calendar  {
  .rmdp-input{
    &, &:active{
    border: none;
    border-bottom: 3px solid #0078d4 !important;
    border-radius: unset !important;
    padding: 0;
    margin: 0;
    height: 25px !important;
    box-shadow: none !important;
  }
  &:focus{
    border: none !important;
    border-bottom: 3px solid #0078d4 !important;
  }
}
}
.live-history-parameter-calendar,
.custom-calendar {
  .rmdp-shadow {
    box-shadow: 0 0 5px #8798ad !important;
  }
  .rmdp-input {
    height: 35px;
    font-size: 12px;
  }
  .rmdp-calendar {
    height: 265px;
  }
  .rmdp-day,
  .rmdp-week-day {
    height: 30px;
    width: 30px;
  }
}
.canvasjs-chart-toolbar {
  z-index: 1001;
}
.MuiSvgIcon-root {
  color: #152c73;
}
.custom-checkbox,
.custom-radio {
  .MuiSvgIcon-root {
    color: inherit;
  }
}
