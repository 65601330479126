:root {
  --rmdp-primary-green: #152C73;
  --rmdp-secondary-green: #87ad92;
  --rmdp-shadow-green: #8faadc;
  --rmdp-today-green: rgba(0, 0, 0, 0.5);
  --rmdp-hover-green: #8faadc;
  --rmdp-deselect-green: #39795c;
  --rmdp-white: #ffffff;
}

.rmdp-wrapper {
  border: none !important;
  box-shadow: none !important;
}
.rmdp-calendar {
  padding: 4px 10px !important;
}
.rmdp-range-hover {
  background-color: var(--rmdp-hover-green) !important;
}
.rmdp-panel-body li {
  background-color: var(--rmdp-primary-green) !important;
  box-shadow: 0 0 2px var(--rmdp-secondary-green) !important;
}

.rmdp-week-day {
  color: var(--rmdp-primary-green) !important;
}

.rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-green) !important;
}

.rmdp-range {
  background-color: var(--rmdp-primary-green) !important;
  box-shadow: 0 0 3px var(--rmdp-shadow-green) !important;
}

.rmdp-arrow {
  border: solid var(--rmdp-primary-green) !important;
  border-width: 0 2px 2px 0 !important;
  margin-top: 6px !important;
  padding: 3px !important;
}

.rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-green) !important;
  box-shadow: 0 0 3px var(--rmdp-secondary-green) !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid var(--rmdp-white) !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-green) !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-green) !important;
}

.rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-green) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-green) !important;
  box-shadow: 0 0 3px var(--rmdp-shadow-green) !important;
}

.rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-green) !important;
}

.b-deselect {
  color: var(--rmdp-deselect-green) !important;
  background-color: white !important;
}

.rmdp-action-button {
  color: var(--rmdp-primary-green) !important;
}

.rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-green) !important;
}

.rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-green) !important;
}

.only.rmdp-month-picker {
  min-width: 500px;
  width: auto;
}
.only.rmdp-month-picker > div:nth-child(2),
.rmdp-day-picker > div:nth-child(2) {
  border-left: 1px solid var(--rmdp-primary-green) !important;
  padding-left: 10px;
}
.rmdp-day-hidden,
.rmdp-day.rmdp-disabled {
  pointer-events: none !important;
}
