@import './css/react-multi-date.scss';
@import './css/global.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
input[type='search']::-ms-clear {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dsportal-wrapper {
  position: fixed;
  top: 45%;
  right: 2px;
  background: transparent;
  cursor: pointer;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.dsportal-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 2px rgb(166 166 166);
  background: #f8f8f8;
}
.dsportal-wrapper .dsportal-link {
  opacity: 0;
}
.dsportal-wrapper:hover .dsportal-link {
  opacity: 1;
}
